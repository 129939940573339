import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { toast } from 'react-toastify';

import { AttributeInterface, InstitutionInterface, StructureInterface } from '../Roles';
import { ConstructionTypesBuild } from '../../enums/construction-type-build';
import AddPin from '../AddPin';
import { SocketService } from '../../services/socket.services';
import {
  ConstructionI,
  GameService,
  GameStateI,
  Player,
  StateStateI,
} from '../../services/game.serivice';
import { AuthContext } from '../../context/context';
import { AREAS } from '../../constants/areas';
import { POINTS } from '../../constants/points';
import Emitter, { EventType } from '../../services/events';
import { ConstructionTypes } from '../../enums/construction-type';
import { ROLES } from '../../constants/roles';
import { CITIES } from '../../constants/cities';
import { ActionType } from '../../enums/action-type';
import Dialog from '../Dialog';
interface MapProps {
  changeGame: (data: StateStateI) => void;
  changeGameByPower: (
    data: StateStateI[],
    card: StructureInterface | InstitutionInterface | AttributeInterface,
  ) => void;
  selectedBuild?: StructureInterface;
  gameId: string;
  setPosition: (country: string) => void;
  yourTurn: boolean;
  country: string;
  constructions: ConstructionI[];
  players?: Player[];
  usedPowers: string[];
  rounds: number;
}

const originalWidth = 1440;
const originalHeight = 1024;
const Map: FC<MapProps> = ({
  changeGame,
  changeGameByPower,
  selectedBuild,
  setPosition,
  gameId,
  country,
  yourTurn,
  constructions,
  players,
  usedPowers,
  rounds,
}) => {
  const canvasRef = useRef(null);
  const { user } = useContext(AuthContext);
  const [showAddPin, setAddPin] = useState<boolean>(false);
  const [selectCity, setSelectCity] = useState<boolean>(false);
  const [selectInstitutionCity, setSelectInstitutionCity] = useState<boolean>(false);
  const [superPower, setSuperPower] = useState<AttributeInterface | undefined>();

  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [successRegion, setSuccessRegion] = useState('');

  const addToMap = useCallback(
    (id: string, procentX: number, procentY: number, action: StructureInterface) => {
      if (!(procentX && procentY)) {
        return;
      }
      if (
        ![
          ConstructionTypesBuild.GREEN_RESOURCE.toString(),
          ConstructionTypesBuild.RESOURCE.toString(),
        ].includes(action.typeBuild!)
      ) {
        return;
      }
      const exist = document.querySelector(`#${id}`);
      const svg = d3.select(canvasRef.current);
      if (exist) {
        const existCount = document.querySelector(`#${id} span`);
        if (existCount) {
          existCount.innerHTML = `${parseFloat(existCount.innerHTML) + 1}`;
        } else {
          svg
            .select(`#${id}`)
            .append('span')
            .html('2')
            .style(
              'background-color',
              action.typeBuild === ConstructionTypesBuild.GREEN_RESOURCE ? '#2C8316' : '#8B5E34',
            )
            .attr('class', 'marker_count');
        }
      } else {
        svg
          .select('#base')
          .append('div')
          .attr('id', id)
          .attr('class', 'marker_block')
          .style('left', `${procentX}px`)
          .style('top', `${procentY}px`)
          .append('div')
          .attr('class', 'marker')
          .style(
            'background-color',
            action.typeBuild === ConstructionTypesBuild.GREEN_RESOURCE ? '#2C8316' : '#8B5E34',
          )
          .style(
            'border',
            action.typeBuild === ConstructionTypesBuild.GREEN_RESOURCE
              ? '2px solid #2C8316'
              : '2px solid #8B5E34',
          )
          .style(
            'background-image',
            action.typeBuild === ConstructionTypesBuild.GREEN_RESOURCE
              ? 'linear-gradient(180deg, #75D100 0%, #009F83 100%)'
              : 'linear-gradient(180deg, #DAA200 0%, #B36600 100%)',
          )
          .append('img')
          .attr('src', `/game/${action.type!}.png`)
          .attr('class', 'marker_image');
      }
    },
    [],
  );

  const convertPoints = useCallback(
    (id: string, build: StateStateI) => {
      const innerHeight = (window.innerHeight * 90) / 100;
      const coefficient = window.innerWidth / innerHeight;
      let newHeight = innerHeight;
      let newWidth = window.innerWidth;
      if (coefficient > 1.4) {
        newWidth = (newHeight / originalHeight) * originalWidth;
      } else {
        newHeight = (newWidth / originalWidth) * originalHeight;
      }
      let x = (build.procentX / originalWidth) * newWidth;
      let y = (build.procentY / originalHeight) * newHeight;
      addToMap(id, x, y, {
        name: build.label,
        type: build.type,
        typeBuild: build.typeBuild,
        country: build.country || '1',
      });
    },
    [addToMap],
  );

  const removeBuild = useCallback((id: string) => {
    const exist = document.querySelector(`#${id}`);
    if (exist) {
      const existCount = document.querySelector(`#${id} span`);
      if (existCount) {
        const newValue = parseFloat(existCount.innerHTML) - 1;
        if (newValue > 1) {
          existCount.innerHTML = `${newValue}`;
        } else {
          exist.removeChild(existCount);
        }
      } else {
        exist.parentElement?.removeChild(exist);
      }
    }
  }, []);

  const connectSocket = useCallback(() => {
    try {
      if (gameId) {
        const socket = SocketService.getInstanceGame(gameId);
        socket.emit('identity', `room:${gameId}`);
        socket.on('game_stream', (data: GameStateI) => {
          const state = data?.state;
          const author = data?.author;
          if (author?.id && user?.id && state?.label === 'add_build' && author?.id !== user?.id) {
            const id = `point_${state.type}_${state.typeBuild}_${state.country || '1'}`;
            convertPoints(id, state);
          }
          if (
            author?.id &&
            user?.id &&
            state?.label === 'change_build' &&
            author?.id !== user?.id
          ) {
            let simpleId = `point_${state.type}_${ConstructionTypesBuild.RESOURCE}_${
              state.country || '1'
            }`;
            if (state.old_type) {
              simpleId = `point_${state.old_type}_${state.typeBuild}_${state.country || '1'}`;
            }
            const exist = document.querySelector(`#${simpleId}`);
            if (exist) {
              const existCount = document.querySelector(`#${simpleId} span`);
              if (existCount) {
                const newValue = parseFloat(existCount.innerHTML) - 1;
                if (newValue > 1) {
                  existCount.innerHTML = `${newValue}`;
                } else {
                  exist.removeChild(existCount);
                }
              } else {
                exist.parentElement?.removeChild(exist);
              }
            }
            const id = `point_${state.type}_${state.typeBuild}_${state.country || '1'}`;
            convertPoints(id, state);
          }

          if (author?.id && user?.id && state?.label === 'remove_build') {
            const id = `point_${state?.type}_${state.typeBuild}_${state.country || '1'}`;
            removeBuild(id);
          }
        });
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
    }
  }, [convertPoints, gameId, removeBuild, user?.id]);

  const closeAddPin = useCallback(() => {
    setPosition('');
    setAddPin(false);
  }, [setPosition]);

  const successAddPin = useCallback(() => {
    const currentCountry: string = country || '1';
    let changedToGreen = false;
    if (selectedBuild?.typeBuild === ConstructionTypesBuild.GREEN_RESOURCE) {
      const simpleId = `point_${selectedBuild.type}_${ConstructionTypesBuild.RESOURCE}_${currentCountry}`;
      const exist = document.querySelector(`#${simpleId}`);
      if (exist) {
        const existCount = document.querySelector(`#${simpleId} span`);
        if (existCount) {
          const newValue = parseFloat(existCount.innerHTML) - 1;
          if (newValue > 1) {
            existCount.innerHTML = `${newValue}`;
          } else {
            exist.removeChild(existCount);
          }
        } else {
          exist.parentElement?.removeChild(exist);
        }
        changedToGreen = true;
      } else {
        setPosition('');
        setAddPin(false);
        return;
      }
    }
    const exist = constructions.find(
      (c) =>
        c.type === selectedBuild?.type &&
        c.typeBuild === selectedBuild?.typeBuild &&
        c.country === currentCountry,
    );
    let procentX = 0;
    let procentY = 0;
    if (exist) {
      procentX = exist.procentX;
      procentY = exist.procentY;
    } else {
      const cityPoints = POINTS[currentCountry];
      const diffPoints = cityPoints.filter(
        (item) =>
          !constructions.some(
            (c) =>
              c.country === item.cityId.toString() &&
              c.procentX === item.x &&
              c.procentY === item.y,
          ),
      );
      procentX = diffPoints[0].x;
      procentY = diffPoints[0].y;
    }
    const id = `point_${selectedBuild?.type}_${selectedBuild?.typeBuild}_${currentCountry}`;
    convertPoints(id, {
      ...selectedBuild!,
      country,
      procentX,
      procentY,
      type: selectedBuild?.type!,
      label: changedToGreen ? 'change_build' : 'add_build',
    });
    changeGame({
      procentX,
      procentY,
      card: selectedBuild!,
      type: selectedBuild?.type!,
      typeBuild: selectedBuild?.typeBuild!,
      card_value: selectedBuild?.card_value!,
      country: currentCountry,
      label: changedToGreen ? 'change_build' : 'add_build',
    });

    // Show success dialog with the current region
    const cityObj = CITIES.find((city) => city.id.toString() === currentCountry);
    const regionName = cityObj?.name.toLowerCase() || '';
    setSuccessRegion(regionName);
    setShowSuccessDialog(true);

    setPosition('');
    setAddPin(false);
  }, [changeGame, convertPoints, country, selectedBuild, setPosition, CITIES]);

  useEffect(() => {
    if (country && selectedBuild && yourTurn) {
      setAddPin(true);
    }
  }, [country, selectedBuild, yourTurn]);

  const callSuperPower = useCallback(() => {
    const cityConstructions = constructions.filter(
      (r) =>
        r.country === country &&
        r.type !== ConstructionTypes.BANK &&
        r.type !== ConstructionTypes.STORE,
    );
    let newConstructions: StateStateI[] = [];
    if (cityConstructions?.length) {
      const consumerRole = ROLES.find((r) => r.roleDb === 'consumer');
      const consumerStructure = consumerRole?.structures.find(
        (s) => s.typeBuild === ConstructionTypesBuild.RESOURCE,
      );
      const consumerGreenStructure = consumerRole?.structures.find(
        (s) => s.typeBuild === ConstructionTypesBuild.GREEN_RESOURCE,
      );
      const governmentRole = ROLES.find((r) => r.roleDb === 'government');
      const governmentStructure = governmentRole?.structures.find(
        (s) => s.typeBuild === ConstructionTypesBuild.RESOURCE,
      );
      const governmentGreenStructure = governmentRole?.structures.find(
        (s) => s.typeBuild === ConstructionTypesBuild.GREEN_RESOURCE,
      );
      const simpleConstructions = cityConstructions.filter(
        (c) => c.typeBuild === ConstructionTypesBuild.RESOURCE,
      );
      const greenConstructions = cityConstructions.filter(
        (c) => c.typeBuild === ConstructionTypesBuild.GREEN_RESOURCE,
      );
      const consumerPlayer = players?.find((p) => p.role === consumerRole?.roleDb);
      if (simpleConstructions?.length) {
        let consumerBuild = constructions.find(
          (s) =>
            s.typeBuild === ConstructionTypesBuild.RESOURCE &&
            s.type === consumerStructure?.type &&
            s.country === country,
        );

        let governmentBuild = constructions.find(
          (s) =>
            s.typeBuild === ConstructionTypesBuild.RESOURCE &&
            s.type === governmentStructure?.type &&
            s.country === country,
        );
        const numStructure = consumerPlayer ? Math.floor(simpleConstructions?.length / 2) : 0;
        const newConsumerStructure = simpleConstructions.slice(0, numStructure);
        const newGovernmentStructure = simpleConstructions.slice(
          numStructure,
          simpleConstructions.length,
        );

        if (!consumerBuild) {
          consumerBuild = newConsumerStructure?.length ? newConsumerStructure?.at(0) : undefined;
        }
        if (!governmentBuild) {
          governmentBuild = newGovernmentStructure?.length
            ? newGovernmentStructure?.at(0)
            : undefined;
        }

        newConstructions = [
          ...newConstructions,
          ...(newConsumerStructure.map((c) => ({
            procentX: consumerBuild ? consumerBuild.procentX : c?.procentX,
            procentY: consumerBuild ? consumerBuild.procentY : c?.procentY,
            card: consumerStructure,
            type: consumerStructure?.type,
            typeBuild: consumerStructure?.typeBuild!,
            card_value: consumerStructure?.card_value!,
            country: country,
            label: 'change_build',
            old_type: c.type,
          })) as StateStateI[]),
          ...(newGovernmentStructure.map((g) => ({
            procentX: governmentBuild ? governmentBuild.procentX : g?.procentX,
            procentY: governmentBuild ? governmentBuild.procentY : g?.procentY,
            card: governmentStructure,
            type: governmentStructure?.type,
            typeBuild: governmentStructure?.typeBuild!,
            card_value: governmentStructure?.card_value!,
            country: country,
            old_type: g.type,
            label: 'change_build',
          })) as StateStateI[]),
        ];
      }
      if (greenConstructions?.length) {
        let consumerGreenBuild = constructions.find(
          (s) =>
            s.typeBuild === ConstructionTypesBuild.GREEN_RESOURCE &&
            s.type === consumerStructure?.type &&
            s.country === country,
        );
        let governmentGreenBuild = constructions.find(
          (s) =>
            s.typeBuild === ConstructionTypesBuild.GREEN_RESOURCE &&
            s.type === governmentStructure?.type &&
            s.country === country,
        );
        const numGreenStructure = consumerPlayer ? Math.floor(greenConstructions?.length / 2) : 0;
        const newConsumerGreenStructure = greenConstructions.slice(0, numGreenStructure);
        const newGovernmentGreenStructure = greenConstructions.slice(
          numGreenStructure,
          greenConstructions.length,
        );

        if (!consumerGreenBuild) {
          consumerGreenBuild = newConsumerGreenStructure?.length
            ? newConsumerGreenStructure?.at(0)
            : undefined;
        }
        if (!governmentGreenBuild) {
          governmentGreenBuild = newGovernmentGreenStructure?.length
            ? newGovernmentGreenStructure?.at(0)
            : undefined;
        }

        newConstructions = [
          ...newConstructions,
          ...(newConsumerGreenStructure.map((c) => ({
            procentX: consumerGreenBuild ? consumerGreenBuild.procentX : c.procentX,
            procentY: consumerGreenBuild ? consumerGreenBuild.procentY : c.procentY,
            card: consumerGreenStructure,
            type: consumerGreenStructure?.type,
            typeBuild: consumerGreenStructure?.typeBuild!,
            card_value: consumerGreenStructure?.card_value!,
            country: country,
            old_type: c.type,
            label: 'change_build',
          })) as StateStateI[]),
          ...(newGovernmentGreenStructure.map((g) => ({
            procentX: governmentGreenBuild ? governmentGreenBuild.procentX : g.procentX,
            procentY: governmentGreenBuild ? governmentGreenBuild.procentY : g.procentY,
            card: governmentGreenStructure,
            type: governmentGreenStructure?.type,
            typeBuild: governmentGreenStructure?.typeBuild!,
            card_value: governmentGreenStructure?.card_value!,
            country: country,
            old_type: g.type,
            label: 'change_build',
          })) as StateStateI[]),
        ];
      }
      if (newConstructions?.length) {
        for (let newConstruction of newConstructions) {
          const oldId = `point_${newConstruction.old_type}_${newConstruction.typeBuild}_${newConstruction.country}`;
          if (oldId) {
            const exist = document.querySelector(`#${oldId}`);
            if (exist) {
              exist.parentElement?.removeChild(exist);
            }
          }
          const id = `point_${newConstruction.type}_${newConstruction.typeBuild}_${newConstruction.country}`;
          convertPoints(id, newConstruction);
        }
      }
    }
    const city = CITIES.find((c) => c.id === parseInt(country));
    changeGameByPower(newConstructions, {
      ...superPower!,
      log: `used Residential Zoning power on ${city?.name}`,
    });
    setSelectCity(false);
    setSuperPower(undefined);
  }, [changeGameByPower, constructions, convertPoints, country, players, superPower]);

  const convertToNonGreen = useCallback(() => {
    let cityConstructions = constructions.filter(
      (r) => r.country === country && r.type !== ConstructionTypes.BANK,
    );
    let newConstructions: StateStateI[] = [];
    let removeConstructions: StateStateI[] = [];
    if (cityConstructions?.length) {
      const businessRole = ROLES.find((r) => r.roleDb === 'business');
      const businessStructure = businessRole?.structures.find(
        (s) => s.typeBuild === ConstructionTypesBuild.RESOURCE,
      );
      let businessBuild = constructions.find(
        (s) =>
          s.typeBuild === ConstructionTypesBuild.RESOURCE &&
          s.type === businessStructure?.type &&
          s.country === country,
      );
      const firstConstruction = cityConstructions?.at(0);
      const procentX = businessBuild ? businessBuild.procentX : firstConstruction?.procentX;
      const procentY = businessBuild ? businessBuild.procentY : firstConstruction?.procentY;
      cityConstructions = cityConstructions.filter(
        (r) =>
          !(
            r.type === ConstructionTypes.INDUSTRY && r.typeBuild === ConstructionTypesBuild.RESOURCE
          ),
      );
      newConstructions = cityConstructions.map((g) => {
        removeConstructions.push(g);
        return {
          procentX,
          procentY,
          card: businessStructure,
          type: businessStructure?.type,
          typeBuild: businessStructure?.typeBuild!,
          card_value: businessStructure?.card_value!,
          country: country,
          old_type: g.type,
          label: 'add_build',
        };
      }) as StateStateI[];
    }
    if (removeConstructions?.length) {
      for (let removeConstruction of removeConstructions) {
        const oldId = `point_${removeConstruction.type}_${removeConstruction.typeBuild}_${removeConstruction.country}`;
        if (oldId) {
          const exist = document.querySelector(`#${oldId}`);
          if (exist) {
            exist.parentElement?.removeChild(exist);
          }
        }
      }
    }

    if (newConstructions?.length) {
      for (let newConstruction of newConstructions) {
        const id = `point_${newConstruction.type}_${newConstruction.typeBuild}_${newConstruction.country}`;
        convertPoints(id, {
          ...newConstruction,
        });
      }
    }

    const city = CITIES.find((c) => c.id === parseInt(country));
    changeGameByPower(newConstructions, {
      ...superPower!,
      log: `played Hostile Takeover power on ${city?.name}`,
    });
    setSelectCity(false);
    setSuperPower(undefined);
  }, [changeGameByPower, constructions, convertPoints, country, superPower]);

  const callDownAllInfrastrucutre = useCallback(() => {
    const city = CITIES.find((c) => c.id === parseInt(country));
    changeGameByPower([], {
      ...superPower!,
      log: `played Protest power on ${city?.name}`,
    });
    setSelectCity(false);
    setSuperPower(undefined);
  }, [changeGameByPower, country, superPower]);

  const callRestoreInfrastrucutre = useCallback(() => {
    const city = CITIES.find((c) => c.id === parseInt(country));
    changeGameByPower([], {
      ...superPower!,
      log: `played Popular Demand on ${city?.name}`,
    });
    setSelectCity(false);
    setSuperPower(undefined);
  }, [changeGameByPower, country, superPower]);

  const checkCallSuperPower = useCallback(() => {
    switch (superPower?.action) {
      case ActionType.BUILDINGS_CONVERT_TO_GOVERNMENT_AND_CONSUMER:
        callSuperPower();
        break;
      case ActionType.DOWN_ALL_INFRASTRUCTURE:
        callDownAllInfrastrucutre();
        break;
      case ActionType.RESTORE_INFRASTRUCTURE_IN_THREE_CITIES:
        callRestoreInfrastrucutre();
        break;
      case ActionType.MAKE_ALL_NON_GREEN_WITHOUT_GOVERNMENT:
        convertToNonGreen();
        break;
      default:
    }
  }, [
    callDownAllInfrastrucutre,
    callRestoreInfrastrucutre,
    callSuperPower,
    convertToNonGreen,
    superPower?.action,
  ]);

  useEffect(() => {
    if (country && selectCity && superPower) {
      checkCallSuperPower();
    }
  }, [checkCallSuperPower, country, selectCity, superPower]);

  const checkCallInstitutionCard = useCallback(
    (selectedCountry: string) => {
      Emitter.emit(EventType.REVERT_SELECT_CITY_CARD, selectedCountry);
      setSelectInstitutionCity(false);
      setPosition('');
    },
    [setPosition],
  );

  useEffect(() => {
    if (country && selectInstitutionCity) {
      checkCallInstitutionCard(country);
    }
  }, [checkCallInstitutionCard, country, selectInstitutionCity]);

  const getChangeGame = useCallback(async () => {
    try {
      if (gameId) {
        const { data } = await GameService.getOne(gameId);
        const constructions =
          data?.state?.constructions && data?.state?.constructions?.length
            ? data?.state?.constructions
            : [];
        const innerHeight = (window.innerHeight * 90) / 100;
        const coefficient = window.innerWidth / innerHeight;
        let newHeight = innerHeight;
        let newWidth = window.innerWidth;
        if (coefficient > 1.4) {
          newWidth = (newHeight / originalHeight) * originalWidth;
        } else {
          newHeight = (newWidth / originalWidth) * originalHeight;
        }
        for (let build of constructions) {
          let x = (build.procentX / originalWidth) * newWidth;
          let y = (build.procentY / originalHeight) * newHeight;
          const id = `point_${build.type}_${build.typeBuild}_${build.country}`;
          addToMap(id, x, y, {
            name: build.label,
            type: build.type,
            typeBuild: build.typeBuild,
            country: build.country || '1',
          });
        }
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
    }
  }, [addToMap, gameId]);

  const addMap = useCallback(async () => {
    const exist = document.querySelector('#base svg');
    if (exist) {
      return;
    }
    const innerHeight = (window.innerHeight * 90) / 100;
    const coefficient = window.innerWidth / innerHeight;
    let newHeight = innerHeight;
    let newWidth = window.innerWidth;
    if (coefficient > 1.4) {
      newWidth = (newHeight / originalHeight) * originalWidth;
    } else {
      newHeight = (newWidth / originalWidth) * originalHeight;
    }
    const svg = d3.select('#base').append('svg').attr('width', newWidth).attr('height', newHeight);

    svg
      .append('image')
      .attr('xlink:href', '/game/map.jpg')
      .attr('width', newWidth)
      .attr('height', newHeight);

    const areas = AREAS;
    for (const area of areas) {
      for (let i = 0; i < area.length; i += 2) {
        area[i] = (area[i] / originalWidth) * newWidth;
        area[i + 1] = (area[i + 1] / originalHeight) * newHeight;
      }
    }

    svg
      .selectAll('polygon')
      .data(areas)
      .enter()
      .append('polygon')
      .attr('code', (_, i) => i + 1)
      .attr('points', (d) => d.join(','))
      .style('fill', 'transparent')
      .style('stroke', 'white')
      .style('stroke-width', '2')
      .on('mouseover', function () {
        d3.select(this).style('fill', 'rgba(255, 255, 255, 0.4)');
      })
      .on('mouseout', function () {
        d3.select(this).style('fill', 'transparent');
      })
      .on('click', function (e) {
        const country = e?.target?.attributes?.code?.nodeValue;
        setPosition(country || '1');
      });
  }, [setPosition]);

  useEffect(() => {
    if (gameId && user?.id) {
      connectSocket();
    }
  }, [connectSocket, gameId, user?.id]);

  useEffect(() => {
    addMap();
  }, [addMap]);

  useEffect(() => {
    if (gameId) {
      setTimeout(() => {
        getChangeGame();
      }, 1000);
    }
  }, [gameId, getChangeGame]);

  useEffect(() => {
    if ((yourTurn && selectedBuild) || selectCity || selectInstitutionCity) {
      d3.selectAll('polygon').attr('pointer-events', 'all');
    } else {
      d3.selectAll('polygon').attr('pointer-events', 'none');
    }
  }, [selectedBuild, yourTurn, selectCity, selectInstitutionCity]);

  useEffect(() => {
    d3.selectAll('polygon').classed('disabled_country', false);
    if (usedPowers.length) {
      const currentPowers = usedPowers.find((r) =>
        r.includes(`government_residential_zoning_${rounds}`),
      );
      if (currentPowers) {
        const zonings = currentPowers.split('_');
        const city = zonings?.at(0);
        d3.select(`polygon[code="${city}"]`).classed('disabled_country', true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usedPowers]);

  useEffect(() => {
    Emitter.on(EventType.CHANGE_SELECT_CITY, (power) => {
      if (power) {
        setSuperPower(power);
        setSelectCity(true);
      }
    });

    return () => {
      Emitter.off(EventType.CHANGE_SELECT_CITY);
    };
  }, []);

  useEffect(() => {
    Emitter.on(EventType.CHANGE_SELECT_CITY_CARD, (selectInstitutionCity = true) => {
      setTimeout(() => {
        setSelectInstitutionCity(selectInstitutionCity);
      }, 500);
    });

    return () => {
      Emitter.off(EventType.CHANGE_SELECT_CITY_CARD);
    };
  }, []);

  useEffect(() => {
    Emitter.on(EventType.ADD_NEW_BUILD, (build: ConstructionI) => {
      const id = `point_${build.type}_${build.typeBuild}_${build.country || '1'}`;
      convertPoints(id, build);
    });

    return () => {
      Emitter.off(EventType.ADD_NEW_BUILD);
    };
  }, [convertPoints]);

  return (
    <>
      <div
        className="h-screen w-full flex flex-row justify-center items-center bg-catalina-blue"
        ref={canvasRef}
        id="game"
      >
        <div id="base" className="relative z-0 max-h-[80%] max-w-full" />
      </div>
      {showAddPin ? (
        <AddPin
          isOpen={showAddPin}
          title={'Are you sure you want to build Structure?'}
          onClose={closeAddPin}
          onSuccess={successAddPin}
        />
      ) : null}
      {showSuccessDialog && (
        <Dialog
          isOpen={showSuccessDialog}
          onClose={() => setShowSuccessDialog(false)}
          title="Structure Created Successfully"
          subtitle="Your structure has been built IN <<region>>"
          region={successRegion}
        />
      )}
    </>
  );
};

export default Map;
