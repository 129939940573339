import React from 'react';
import { useNavigate } from 'react-router-dom';
import ChevronRight from '../../assets/ChevronRight';
import Footer from '../../components/Footer';

const Tutorial: React.FC = () => {
  const navigate = useNavigate();
  const back = () => {
    navigate(-1);
  };

  return (
    <div className="w-full h-screen">
      <div className="flex flex-col justify-between h-full relative z-10 bg-derby">
        <div className="flex flex-col justify-center w-full">
          <div className="flex flex-row p-6">
            <button
              className="flex flex-row bg-transparent outline-none border-0 cursor-pointer"
              onClick={back}
            >
              <ChevronRight />
            </button>
            <p className="m-0 text-3xl font-medium text-mine_shaft ml-2 uppercase">Tutorial</p>
          </div>

          <div className="mt-6 flex flex-row justify-center overflow-y-auto bg-derby">
            <div className="flex flex-col max-w-5xl w-full px-6">
              <div
                className="w-full relative border-2 border-mine_shaft rounded-lg overflow-hidden"
                style={{ aspectRatio: '16/9' }}
              >
                <video
                  className="w-full h-full object-cover"
                  controls
                  src="https://video.wixstatic.com/video/be19d5_000874f9072244c697f16c18a5f82133/1080p/mp4/file.mp4"
                />
              </div>
            </div>
          </div>
        </div>
        <Footer isRules={true} />
      </div>
    </div>
  );
};

export default Tutorial;
