import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

import {
  CardI,
  CardsI,
  ConstructionI,
  GameI,
  GameService,
  GameStateI,
  StateCardsI,
  StateStateI,
} from '../../services/game.serivice';
import PlayersGame from '../../components/PlayersGame';
import Resources from '../../components/Resources';
import Map from '../../components/Map';
import { SocketService } from '../../services/socket.services';
import { AuthContext } from '../../context/context';
import BottomGameBlock from '../../components/BottomGameBlock';
import {
  AttributeInterface,
  InstitutionInterface,
  RoleInterface,
  StructureInterface,
} from '../../components/Roles';
import { MODIFIER_GREEN_RESOURCES, MODIFIER_RESOURCES, ROLES } from '../../constants/roles';
import WinnerModal from '../../components/WinnerModal';
import OverModal from '../../components/OverModal';
import DangerCard from '../../components/DangerCard';
import { ActionType } from '../../enums/action-type';
import { ConstructionTypes } from '../../enums/construction-type';
import { useOpenReplay } from '../../hooks/use-openreplay';
import { ConstructionTypesBuild } from '../../enums/construction-type-build';

const Game: FC = () => {
  const [tracker] = useOpenReplay();
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useContext(AuthContext);
  const [game, setGame] = useState<GameI | undefined>();
  const [currentActor, setCurrentActor] = useState<string | undefined>();
  const [meCards, setMeCards] = useState<CardI | undefined>();
  const [selectedBuild, setBuild] = useState<StructureInterface | undefined>();
  const [constructions, setConstructions] = useState<ConstructionI[]>([]);
  const [constructionCount, setConstructionCount] = useState<number>(0);
  const [greenConstructionCount, setGreenConstructionCount] = useState<number>(0);
  const [currentCards, setCurrentCards] = useState<StructureInterface[]>([]);
  const [resourceCount, setResourceCount] = useState<number>(0);
  const [greenResourceCount, setGreenResourceCount] = useState<number>(0);
  const [danger, setDanger] = useState<string | undefined>(undefined);
  const [dangerMajor, setDangerMajor] = useState<string | undefined>(undefined);
  const [institutuinCards, setInstitutuinCards] = useState<InstitutionInterface[]>([]);
  const [modifier, setModifier] = useState<number>(0);
  const [emission, setEmission] = useState<number>(0);
  const [meRole, setMeRole] = useState<RoleInterface>();
  const [country, setCountry] = useState<string>('');
  const [winnerModal, setWinnerModal] = useState(false);
  const [overModal, setOverModal] = useState(false);
  const [dangerModal, setDangerModal] = useState(false);
  const [dangerMajorModal, setDangerMajorModal] = useState(false);
  const [connected, setConnected] = useState<boolean>(false);

  const getNewCards = (currentCards: StateCardsI) => {
    const constructions =
      game?.state.constructions && game?.state.constructions?.length
        ? game?.state.constructions?.filter((c) => !!c)
        : [];

    const newCards = Object.keys(currentCards)?.reduce((t, r) => {
      const player = game?.players.find((p) => p.id === r);
      const role = ROLES.find((rl) => rl.roleDb === player?.role);
      const structure = role?.structures[0];
      const construction = constructions.filter((c) => c.type === structure?.type);
      let numElements = Math.floor(construction.length / 2);
      numElements = numElements < 1 ? 1 : numElements;
      let numInstitutionCards = Math.floor(numElements / 2);

      const resourcesMap =
        Array.apply(null, Array(currentCards[r]?.remainedResources))?.map(
          () => ConstructionTypesBuild.RESOURCE,
        ) || [];
      const greenResourcesMap =
        Array.apply(null, Array(currentCards[r]?.remainedGreenResources))?.map(
          () => ConstructionTypesBuild.GREEN_RESOURCE,
        ) || [];

      let institutionsMap = currentCards[r]?.remainedInstitutions?.map((r) => r?.card_value) || [];
      institutionsMap = institutionsMap.slice().sort(() => 0.5 - Math.random());

      let remainedCards = [...resourcesMap, ...greenResourcesMap];

      const usedPowers = game?.state.usedPowers ? game.state.usedPowers : [];
      const greenCards = usedPowers?.find((r) => r.includes('_five_green_resource_cards_to_top_'));
      let cardRole;
      let nrCards;
      if (greenCards) {
        const cards = greenCards?.split('_five_green_resource_cards_to_top_');
        cardRole = cards?.at(0);
        nrCards = (cards?.at(1) && parseFloat(cards?.at(1)!)) || 0;
      }

      const simpleCards = usedPowers?.find((r) => r.includes('_consumer_more_goods_'));
      if (simpleCards) {
        const cards = simpleCards?.split('_consumer_more_goods_');
        cardRole = cards?.at(0);
        nrCards = (cards?.at(1) && parseFloat(cards?.at(1)!)) || 0;
      }

      if (nrCards && nrCards > 0 && cardRole === player?.role && greenCards) {
        remainedCards = [...greenResourcesMap, ...resourcesMap];
        if (nrCards > numElements) {
          numElements = numElements + numInstitutionCards;
          numInstitutionCards = 0;
        }
      } else if (nrCards && nrCards > 0 && cardRole === player?.role && simpleCards) {
        remainedCards = [...resourcesMap, ...greenResourcesMap];
        if (nrCards > numElements) {
          numElements = numElements + numInstitutionCards;
          numInstitutionCards = 0;
        }
      } else {
        remainedCards = remainedCards.slice().sort(() => 0.5 - Math.random());
      }

      let resources = currentCards[r]?.resources;
      let greenResources = currentCards[r]?.greenResources;
      let institutions = currentCards[r]?.institutions;
      let remainedInstitutions = currentCards[r]?.remainedInstitutions;
      let remainedResources = currentCards[r]?.remainedResources || 0;
      let remainedGreenResources = currentCards[r]?.remainedGreenResources || 0;
      let remainedInstitutionCount = currentCards[r]?.remainedInstitutionCount || 0;

      if (numElements >= remainedCards.length) {
        for (let i = 0; i < remainedCards.length; i++) {
          const card = remainedCards[i];
          if (card === ConstructionTypesBuild.RESOURCE) {
            const resource = role?.structures?.find(
              (r) => r.typeBuild === ConstructionTypesBuild.RESOURCE,
            );
            resources?.push(resource!);
            remainedResources = remainedResources - 1;
          } else if (card === ConstructionTypesBuild.GREEN_RESOURCE) {
            const resource = role?.structures?.find(
              (r) => r.typeBuild === ConstructionTypesBuild.GREEN_RESOURCE,
            );
            greenResources?.push(resource!);
            remainedGreenResources = remainedGreenResources - 1;
          }
        }
      } else {
        for (let i = 0; i < numElements; i++) {
          const card = remainedCards[i];
          if (card === ConstructionTypesBuild.RESOURCE) {
            const resource = role?.structures?.find(
              (r) => r.typeBuild === ConstructionTypesBuild.RESOURCE,
            );
            resources?.push(resource!);
            remainedResources = remainedResources - 1;
          } else if (card === ConstructionTypesBuild.GREEN_RESOURCE) {
            const resource = role?.structures?.find(
              (r) => r.typeBuild === ConstructionTypesBuild.GREEN_RESOURCE,
            );
            greenResources?.push(resource!);
            remainedGreenResources = remainedGreenResources - 1;
          }
        }
      }

      if (remainedInstitutions?.length) {
        for (let i = 0; i < numInstitutionCards; i++) {
          const card = institutionsMap[i];
          const institution = remainedInstitutions?.find((i) => i?.card_value === card);
          remainedInstitutions = remainedInstitutions?.filter((i) => i?.card_value !== card);
          if (institution) {
            institutions?.push(institution!);
            remainedInstitutionCount = remainedInstitutionCount - 1;
          }
        }
      }

      return {
        ...t,
        [r]: {
          resources,
          remainedResources,
          greenResources,
          remainedGreenResources,
          institutions,
          remainedInstitutions,
          remainedInstitutionCount,
        },
      };
    }, {});
    return newCards;
  };

  const nextActor = useCallback(async () => {
    try {
      if (game?.id && user?.id === currentActor) {
        const totalPlayers = game.players.map((r) => r.id);
        const index = totalPlayers.findIndex((r) => r === currentActor);
        const players = totalPlayers.filter((r) => r !== currentActor);
        const actor = players[index] || players[0];
        let newRound = [...(game?.state?.round || []), currentActor!];
        let emission = game?.state?.emission;
        let modifier = game?.state?.modifier;
        let currentCards = game?.state?.currentCards!;
        let lastModifier = game?.state?.lastModifier;
        let isWinner = false;
        let isOver = false;
        let usedPowers = game.state.usedPowers ? game.state.usedPowers : [];
        let rounds = game.state.rounds || 1;
        let lastEmission = game.state.lastEmission || 1;
        let dangerSubmited = true;
        let constructionsLastRound = game.state.constructionsLastRound || [];
        if (newRound.length === game.players.length) {
          newRound = [];
          dangerSubmited = false;
          lastModifier = modifier;
          lastEmission = emission;
          const newEmission = emission + modifier;
          emission = newEmission > 0 ? newEmission : 0;
          isWinner = !emission;
          isOver = emission >= 600;
          currentCards = getNewCards(currentCards);
          const greenCards = usedPowers?.find((r) =>
            r.includes('_five_green_resource_cards_to_top_'),
          );
          if (greenCards) {
            const cards = greenCards?.split('_five_green_resource_cards_to_top_');
            const cardRole = cards?.at(0);
            const nrCards = (cards?.at(1) && parseFloat(cards?.at(1)!)) || 0;
            const roleCard = game.players.find((p) => p.role === cardRole);
            const currentConstructions = game?.state?.constructions?.filter((c) => !!c) || [];
            const roleConstructions =
              currentConstructions?.filter((c) => c.userId === roleCard?.id) || [];
            const numElements = Math.floor(roleConstructions.length / 2);
            const numInstitutionCards = Math.floor(numElements / 2);
            const remainedGreenCards = nrCards - numElements - numInstitutionCards;
            const newCards =
              remainedGreenCards > 0
                ? `${cardRole}_five_green_resource_cards_to_top_${remainedGreenCards}`
                : null;
            usedPowers = usedPowers
              ?.map((r) => {
                if (r === greenCards) {
                  return newCards;
                }
                return r;
              })
              ?.filter((p) => !!p) as string[];
          }
          const simpleCards = usedPowers?.find((r) => r.includes('_consumer_more_goods_'));
          if (simpleCards) {
            const cards = simpleCards?.split('_consumer_more_goods_');
            const cardRole = cards?.at(0);
            const nrCards = (cards?.at(1) && parseFloat(cards?.at(1)!)) || 0;
            const roleCard = game.players.find((p) => p.role === cardRole);
            const roleConstructions =
              game?.state?.constructions?.filter((c) => c.userId === roleCard?.id) || [];
            const numElements = Math.floor(roleConstructions.length / 2);
            const numInstitutionCards = Math.floor(numElements / 2);
            const remainedSimpleCards = nrCards - numElements - numInstitutionCards;
            const newCards =
              remainedSimpleCards > 0
                ? `${cardRole}_consumer_more_goods_${remainedSimpleCards}`
                : null;
            usedPowers = usedPowers
              ?.map((r) => {
                if (r === simpleCards) {
                  return newCards;
                }
                return r;
              })
              ?.filter((p) => !!p) as string[];
          }

          const allConstructions = game?.state?.constructions?.length
            ? game?.state?.constructions?.filter((c) => !!c)
            : [];
          let city: string | undefined = undefined;

          const isSustainability = usedPowers.some(
            (r) => r === `business_investmen_in_iustainability_${rounds}`,
          );

          const otherBuilds =
            allConstructions?.filter(
              (r) => r.typeBuild === ConstructionTypesBuild.RESOURCE && r.country !== city,
            ) || [];
          let otherBuildsNr = otherBuilds?.length;
          if (isSustainability) {
            const simpleFactories =
              allConstructions?.filter(
                (r) =>
                  r.typeBuild === ConstructionTypesBuild.RESOURCE &&
                  r.type === ConstructionTypes.INDUSTRY,
              ) || [];
            const factoriesNr = Math.round(simpleFactories?.length / 2);
            otherBuildsNr = otherBuildsNr - factoriesNr;
          }

          const otherGreenBuilds =
            allConstructions?.filter(
              (r) => r.typeBuild === ConstructionTypesBuild.GREEN_RESOURCE,
            ) || [];
          let otherGreenBuildsNr = otherGreenBuilds?.length;
          if (isSustainability) {
            const greenFactories =
              allConstructions?.filter(
                (r) =>
                  r.typeBuild === ConstructionTypesBuild.GREEN_RESOURCE &&
                  r.type === ConstructionTypes.INDUSTRY,
              ) || [];
            const greenFactoriesNr = Math.round(greenFactories?.length / 2);
            otherGreenBuildsNr = otherGreenBuildsNr - greenFactoriesNr;
          }
          const isUsedAccelerator = usedPowers.some((r) => r === `business_r_d_x_${rounds}`);
          let anotherModifier = 0;
          if (isUsedAccelerator) {
            const greenConstruction = constructionsLastRound?.filter(
              (r) => r === ConstructionTypesBuild.GREEN_RESOURCE,
            );
            otherGreenBuildsNr = otherGreenBuildsNr - greenConstruction?.length;
            anotherModifier = greenConstruction?.length * MODIFIER_GREEN_RESOURCES * 2;
          }
          modifier =
            otherBuildsNr * MODIFIER_RESOURCES +
            otherGreenBuildsNr * MODIFIER_GREEN_RESOURCES +
            anotherModifier;
          rounds = rounds + 1;
          constructionsLastRound = [];
        }
        usedPowers = usedPowers.filter((r) => r !== `science_research_grant_${rounds - 1}`);
        await GameService.addActions(game.id, {
          state: {
            type: 'skip',
            procentX: 0,
            procentY: 0,
            typeBuild: '',
            label: '',
          },
          type: 'visible_log',
          worldState: {
            ...game.state,
            currentActor: actor,
            currentCards,
            emission,
            lastEmission,
            modifier,
            turnFull: [],
            constructionsLastRound,
            round: newRound,
            isWinner,
            isOver,
            lastModifier,
            usedPowers,
            rounds,
            minorDanger: '',
            dangerSubmited,
            danger: '',
          },
        });
        setCurrentActor(actor);
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActor, game?.id, game?.players, game?.state, user?.id]);

  const getGame = useCallback(async () => {
    try {
      const { data } = await GameService.getOne(params?.gameId!);
      setGame(data);
    } catch (e) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, params?.gameId]);

  const connectSocket = useCallback(
    (gameId: string) => {
      try {
        if (gameId) {
          const socket = SocketService.getInstanceGame(gameId);
          setConnected(true);
          socket.emit('identity', `room:${gameId}`);
          socket.on('game_stream', (data: GameStateI) => {
            if (data.state.isEnded) {
              navigate('/');
            } else {
              getGame();
            }
          });
        }
      } catch (e: any) {
        const message = e?.response?.statusText || 'Error';
        toast(message, { type: 'error' });
      }
    },
    [getGame, navigate],
  );

  useEffect(() => {
    if (user?.id && game?.id) {
      setCurrentActor(game?.state?.currentActor);
      const role = game.players?.find((r) => r.id === user?.id);
      setMeRole(role as RoleInterface);
      const newEmission = game?.state?.emission;
      if (newEmission <= 0 && game?.state?.isWinner) {
        setWinnerModal(true);
      }
      if (newEmission >= 600 && game?.state?.isOver) {
        setOverModal(true);
      }
      if (game?.state?.minorDanger) {
        setDangerModal(true);
        setDanger(game?.state?.minorDanger);
      } else {
        setDangerModal(false);
        setDanger('');
      }
      if (game?.state?.danger && !game?.state?.minorDanger) {
        setDangerMajorModal(true);
        setDangerMajor(game?.state?.danger);
      } else {
        setDangerMajorModal(true);
        setDangerMajor('');
      }
      setEmission(newEmission);
      setModifier(game?.state?.modifier || 0);
      if (game?.state?.constructions && game?.state?.constructions?.length) {
        const gameConstructions = game?.state?.constructions.filter((r) => {
          return r;
        });
        setConstructions(gameConstructions);
        const constructions = gameConstructions.filter((r) => {
          return r && r?.userId === user?.id;
        });
        const greenConstructionCount = constructions?.length
          ? constructions?.filter((r) => r.typeBuild === ConstructionTypesBuild.GREEN_RESOURCE)
              ?.length
          : 0;
        const constructionCount = constructions?.length
          ? constructions?.filter((r) => r.typeBuild === ConstructionTypesBuild.RESOURCE)?.length
          : 0;
        setGreenConstructionCount(greenConstructionCount);
        setConstructionCount(constructionCount);
      }
      if (game?.state?.currentCards && Object.keys(game?.state?.currentCards)?.length) {
        const currentCards = game?.state?.currentCards[user?.id!];
        setCurrentCards([
          ...(currentCards?.resources || []),
          ...(currentCards?.greenResources || []),
        ] as StructureInterface[]);
        setInstitutuinCards(currentCards?.institutions || []);
        setResourceCount(currentCards?.resources?.length || 0);
        setGreenResourceCount(currentCards?.greenResources?.length || 0);
        setMeCards({
          resources: currentCards?.remainedResources || 0,
          greenResources: currentCards?.remainedGreenResources || 0,
          institutions: currentCards?.remainedInstitutionCount || 0,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [game, user?.id]);

  useEffect(() => {
    if (game?.state?.turnFull?.length) {
      if (game?.state?.turnFull?.includes('action')) {
        nextActor();
        return;
      }
      if (game?.state?.turnFull?.length === 2) {
        nextActor();
        return;
      }
    }
  }, [game?.state?.turnFull, nextActor]);

  useEffect(() => {
    if (!params?.gameId) {
      navigate('/');
    } else {
      getGame();
    }
  }, [getGame, navigate, params?.gameId, tracker]);

  useEffect(() => {
    if (params?.gameId && tracker) {
      tracker.setMetadata('gameId', params.gameId);
    }
  }, [params?.gameId, tracker]);

  useEffect(() => {
    if (params?.gameId) {
      connectSocket(params?.gameId);
    }
  }, [connectSocket, params?.gameId]);

  useEffect(() => {
    return () => {
      if (connected) {
        SocketService.disconnectGame(params?.gameId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected]);

  const getUsingGreenCars = useCallback(() => {
    let usedCards = 2;
    if (game) {
      let usedPowers = game.state.usedPowers?.length ? game.state.usedPowers : [];
      const rounds = game.state.rounds || 1;
      usedCards =
        (meRole?.role === 'science' && usedPowers.includes(`science_research_grant_${rounds}`)) ||
        usedPowers.includes(`environmental_activist_mass_rally_${rounds}`)
          ? 1
          : 2;
      const isEcologicalEducation = usedPowers.find(
        (r) => r === `${meRole?.role}_environmental_activist_ecological_education_${rounds}`,
      );
      if (isEcologicalEducation) {
        return 1;
      }
    }
    return usedCards;
  }, [game, meRole?.role]);

  const changeGame = useCallback(
    async (body: StateStateI) => {
      try {
        if (game?.id) {
          let constructions: ConstructionI[] = [];
          let currentCards = {};
          let actionTurn: string = body.label;
          let newModifier = 0;
          let usedPowers = game.state.usedPowers?.length ? game.state.usedPowers : [];
          let constructionsLastRound = game?.state?.constructionsLastRound?.length
            ? game.state.constructionsLastRound
            : [];
          const rounds = game.state.rounds || 1;
          if (user?.id) {
            const oldCurrentCards =
              game?.state?.currentCards && Object.keys(game?.state?.currentCards)?.length
                ? game?.state?.currentCards[user?.id!]
                : {
                    resources: [],
                    remainedResources: 0,
                    greenResources: [],
                    remainedGreenResources: 0,
                    institutions: [],
                    remainedInstitutions: [],
                    remainedInstitutionCount: 0,
                  };
            const oldModifier = game?.state?.modifier;
            let resources = oldCurrentCards?.resources;
            let greenResources = oldCurrentCards?.greenResources;
            let institutions = oldCurrentCards?.institutions;
            if (body.typeBuild === ConstructionTypesBuild.RESOURCE) {
              const usedCards =
                meRole?.role === 'science' &&
                usedPowers.includes(`science_research_grant_${rounds}`)
                  ? 1
                  : 3;
              resources?.splice(0, usedCards);
              newModifier = oldModifier + 5;
            } else if (body.typeBuild === ConstructionTypesBuild.GREEN_RESOURCE) {
              const usedCards = getUsingGreenCars();
              greenResources?.splice(0, usedCards);
              newModifier = oldModifier - 5;
            } else if (body.typeBuild === ConstructionTypesBuild.INSTITUTION) {
              const institution = institutions?.find((i) => i?.card_value === body?.card_value);
              institutions = institutions?.filter((i) => i?.card_value !== body?.card_value);
              newModifier = oldModifier + (institution?.modifier || 0);
            } else {
              newModifier = oldModifier;
            }
            currentCards = {
              ...game.state.currentCards,
              [user?.id]: { ...currentCards, resources, greenResources, institutions },
            };
            let oldConstructions =
              game?.state?.constructions && game?.state?.constructions?.length
                ? game?.state?.constructions
                : [];

            if (
              ['change_build', 'add_build'].includes(body.label!) &&
              [
                ConstructionTypesBuild.GREEN_RESOURCE.toString(),
                ConstructionTypesBuild.RESOURCE.toString(),
              ].includes(body.typeBuild!)
            ) {
              actionTurn = '';
              if (body.label !== 'change_build') {
                oldConstructions = [
                  ...oldConstructions,
                  {
                    ...body,
                    label: body.label ? body.label : 'add_build',
                  } as unknown as ConstructionI,
                ];
              } else {
                let index = oldConstructions.findIndex(
                  (r) =>
                    r.country === body.country &&
                    r.type === body.type &&
                    r.typeBuild === ConstructionTypesBuild.RESOURCE,
                );
                if (index !== -1) {
                  oldConstructions[index] = {
                    ...oldConstructions[index],
                    ...body,
                  };
                }
              }
            }
            constructions = oldConstructions;
          }
          const newTurn = [
            ...(game?.state?.turnFull || []),
            actionTurn?.length ? actionTurn : 'add_build',
          ];
          if (body?.card_value === 'science_research_grant') {
            usedPowers = [...usedPowers, `science_research_grant_${rounds + 1}`];
          }
          if (body?.card_value === 'business_investmen_in_iustainability') {
            usedPowers = [...usedPowers, `business_investmen_in_iustainability_${rounds + 1}`];
          }
          if (body?.card?.use_power?.length) {
            usedPowers = [...usedPowers, body?.card?.use_power];
          }
          if (
            ['change_build', 'add_build'].includes(body.label) &&
            [
              ConstructionTypesBuild.RESOURCE.toString(),
              ConstructionTypesBuild.GREEN_RESOURCE.toString(),
            ].includes(body.typeBuild)
          ) {
            constructionsLastRound = [...constructionsLastRound, body.typeBuild];
          }
          const worldState = {
            ...game.state,
            constructions,
            currentCards,
            modifier: newModifier,
            emission: game?.state?.emission,
            turnFull: newTurn,
            constructionsLastRound,
            usedPowers,
            minorDanger: '',
            dangerSubmited: true,
            danger: '',
            usedCards: [...game.state.usedCards, body.card?.action!],
          };
          await GameService.addActions(game?.id, {
            state: {
              ...body,
              label: body.label ? body.label : 'add_build',
            },
            type: 'visible_log',
            worldState,
          });
          setCountry('');
          setBuild(undefined);
        }
      } catch (e: any) {
        const message = e?.response?.statusText || 'Error';
        toast(message, { type: 'error' });
      }
    },
    [game, getUsingGreenCars, meRole?.role, user?.id],
  );

  const changeSuperPower = async (
    card: StructureInterface,
    updatedConstructions?: ConstructionI[],
    role?: string,
    power?: string,
  ) => {
    try {
      if (game?.id && card) {
        const constructions: ConstructionI[] | undefined = updatedConstructions?.length
          ? updatedConstructions
          : game.state.constructions;
        const oldCurrentCards: StateCardsI =
          game?.state?.currentCards && Object.keys(game?.state?.currentCards)?.length
            ? game?.state?.currentCards
            : {};
        let currentCards = oldCurrentCards;
        if (card.action !== ActionType.MAKE_ONE_YOUR_BUILD_TO_GREEN) {
          currentCards = Object.keys(oldCurrentCards)?.reduce((t, r) => {
            const cardUser = game.players.find((u) => u.role === role);
            if (cardUser && cardUser.id && cardUser.id === r) {
              let greenResources = oldCurrentCards[r]?.greenResources;
              greenResources?.splice(0, 1);
              return {
                ...t,
                [r]: {
                  ...oldCurrentCards[r],
                  greenResources,
                },
              };
            }
            return {
              ...t,
              [r]: oldCurrentCards[r],
            };
          }, {});
        }

        const newTurn = [...(game?.state?.turnFull || []), ConstructionTypesBuild.ACTION];
        let usedPowers = game.state.usedPowers?.length ? game.state.usedPowers : [];

        if (power?.length) {
          usedPowers = [...usedPowers, power];
        }

        const worldState = {
          ...game.state,
          usedPowers,
          constructions,
          currentCards,
          turnFull: newTurn,
          minorDanger: '',
          dangerSubmited: true,
          danger: '',
          usedCards: [...game.state.usedCards, card?.action!],
        };
        await GameService.addActions(game?.id, {
          state: {
            procentX: 0,
            procentY: 0,
            type: '',
            card,
            label: ConstructionTypesBuild.INSTITUTION,
            typeBuild: ConstructionTypesBuild.INSTITUTION,
            card_value: card?.card_value!,
            country,
          },
          type: 'visible_log',
          worldState,
        });
        setCountry('');
        setBuild(undefined);
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
    } finally {
      setCountry('');
      setBuild(undefined);
    }
  };

  const changeInstitution = async (
    card: InstitutionInterface & { currentModifier: number },
    updatedConstructions?: ConstructionI[],
    usedPower?: string,
    cardsToHand?: { card: string; role: string }[],
    nrResources?: number,
  ) => {
    try {
      if (game?.id && card) {
        const constructions: ConstructionI[] | undefined = updatedConstructions?.length
          ? updatedConstructions
          : game.state.constructions;
        const oldCurrentCards: CardsI | undefined =
          game?.state?.currentCards && Object.keys(game?.state?.currentCards)?.length
            ? game?.state?.currentCards[user?.id!]
            : undefined;
        const institutions = oldCurrentCards?.institutions?.filter(
          (i) => i?.card_value !== card?.card_value,
        );
        let currentCards: StateCardsI = {
          ...game.state.currentCards,
          [user?.id!]: { ...oldCurrentCards, institutions },
        };
        let newModifier = 0;
        const oldModifier = game?.state?.modifier;
        newModifier = oldModifier + card.currentModifier;
        const newTurn = [...(game?.state?.turnFull || []), ConstructionTypesBuild.PART_ACTION];
        let usedPowers = game.state.usedPowers?.length ? game.state.usedPowers : [];
        if (usedPower !== 'environmental_activist_mass_rally_') {
          const rounds = game.state.rounds || 1;
          usedPowers = [
            ...usedPowers,
            `${usedPower}${rounds + 1}`,
            `${usedPower}${rounds + 2}`,
            `${usedPower}${rounds + 3}`,
          ];
        }
        const rounds = game.state.rounds || 1;
        if (usedPower === 'environmental_activist_mass_rally_') {
          usedPowers = [...usedPowers, `${usedPower}${rounds + 1}`];
        }

        if (card.action === ActionType.BUSINESS_R_D_X) {
          usedPowers = [...usedPowers, `business_r_d_x_${rounds}`];
        }

        if (cardsToHand?.length) {
          currentCards = Object.keys(currentCards)?.reduce((t, r) => {
            const cardUser = game.players.find((u) => u.id === r);
            const card = cardsToHand.find((c) => c.role === cardUser?.role);
            if (card?.card) {
              const institution = currentCards[r]?.remainedInstitutions?.find(
                (i) => i.action === card.card,
              );
              if (institution && institution?.action) {
                const remainedInstitutions = currentCards[r]?.remainedInstitutions?.filter(
                  (i) => i.action !== card.card,
                );
                return {
                  ...t,
                  [r]: {
                    ...currentCards[r],
                    institutions: [...(currentCards[r]?.institutions || []), institution],
                    remainedInstitutions,
                    remainedInstitutionCount: (currentCards[r]?.remainedInstitutionCount || 1) - 1,
                  },
                };
              }
              return {
                ...t,
                [r]: currentCards[r],
              };
            }
            return {
              ...t,
              [r]: currentCards[r],
            };
          }, {});
        }

        if (card.action === ActionType.GOVERNMENT_CORPORATE_POLLUTION_TAX && nrResources) {
          const player = game?.players.find((p) => p.role === 'business');
          if (player && player.id) {
            const governmentPlayer = game?.players.find((p) => p.role === 'government');
            currentCards = Object.keys(currentCards)?.reduce((t, r) => {
              if (r === player.id) {
                let resources = currentCards[r]?.resources || [];
                let remainedResources = currentCards[r]?.remainedResources;

                for (let nrResource = 0; nrResource < nrResources; nrResource++) {
                  if (resources?.length) {
                    delete resources[0];
                  } else {
                    remainedResources = (currentCards[r]?.remainedResources || 0) - 1;
                  }
                  resources = resources.filter((c) => !!c);
                }

                return {
                  ...t,
                  [r]: {
                    ...currentCards[r],
                    resources,
                    remainedResources,
                  },
                };
              } else if (governmentPlayer && r === governmentPlayer.id) {
                let newCard: StructureInterface | undefined;
                if (currentCards[r] && currentCards[r]?.resources?.length) {
                  newCard = currentCards[r]?.resources?.at(0);
                } else {
                  const currentGovernmentPlayer = ROLES.find(
                    (r) => r.roleDb === governmentPlayer.role,
                  );
                  newCard = currentGovernmentPlayer?.structures?.find(
                    (c) => c.typeBuild === ConstructionTypesBuild.RESOURCE,
                  ) as StructureInterface;
                }

                let newCards = [];
                for (let nrResource = 0; nrResource < nrResources; nrResource++) {
                  newCards.push(newCard);
                }
                newCards = newCards.filter((c) => !!c);

                return {
                  ...t,
                  [r]: {
                    ...currentCards[r],
                    resources: newCard
                      ? [...(currentCards[r]?.resources || []), ...(newCards || [])]
                      : currentCards[r]?.resources,
                  },
                };
              }
              return {
                ...t,
                [r]: currentCards[r],
              };
            }, {});
          }
        }

        const worldState = {
          ...game.state,
          constructions,
          currentCards,
          modifier: newModifier,
          turnFull: newTurn,
          usedPowers,
          minorDanger: '',
          dangerSubmited: true,
          danger: '',
          usedCards: [...game.state.usedCards, card?.action!],
        };
        await GameService.addActions(game?.id, {
          state: {
            procentX: 0,
            procentY: 0,
            type: '',
            card,
            label: ConstructionTypesBuild.INSTITUTION,
            typeBuild: ConstructionTypesBuild.INSTITUTION,
            card_value: card?.card_value!,
            country,
          },
          type: 'visible_log',
          worldState,
        });
        setCountry('');
        setBuild(undefined);
      }
    } catch (e: any) {
      const message = e?.response?.statusText || 'Error';
      toast(message, { type: 'error' });
    } finally {
      setCountry('');
      setBuild(undefined);
    }
  };

  const changeAction = (action?: StructureInterface) => {
    if (action) {
      changeGame({
        procentX: 0,
        procentY: 0,
        type: '',
        card: action,
        label:
          action.action === ActionType.FIVE_GREEN_RESOURCE_CARDS_TO_TOP
            ? ConstructionTypesBuild.PART_ACTION
            : ConstructionTypesBuild.ACTION,
        typeBuild: ConstructionTypesBuild.ACTION,
        card_value: action?.card_value!,
        country,
      });
      setCountry('');
      setBuild(undefined);
    }
  };

  const endGame = async () => {
    try {
      if (game?.id) {
        setWinnerModal(false);
        setOverModal(false);
        await GameService.end(game?.id);
        navigate('/');
      }
    } catch (e) {
      navigate('/');
    }
  };

  const changeGameByPower = useCallback(
    async (
      body: StateStateI[],
      card: StructureInterface | InstitutionInterface | AttributeInterface,
    ) => {
      try {
        if (game?.id) {
          let modifier = game?.state?.modifier;
          let oldConstructions: ConstructionI[] =
            game?.state?.constructions && game?.state?.constructions?.length
              ? game?.state?.constructions
              : [];
          let usedPowers = game.state.usedPowers?.length ? game.state.usedPowers : [];
          const rounds = game.state.rounds || 1;
          if (user?.id) {
            for (let construction of body) {
              const removedConstruction = oldConstructions.find(
                (c) => c.country === construction.country && c.type === construction.old_type,
              );
              oldConstructions = oldConstructions.map((r) => {
                if (r.country === construction.country && r.type === construction.old_type) {
                  delete construction.old_type;
                  return {
                    ...r,
                    ...construction,
                  };
                }
                return r;
              });

              if (removedConstruction && removedConstruction.country) {
                await GameService.addActions(game?.id, {
                  state: {
                    ...removedConstruction,
                    label: 'remove_build',
                  },
                  type: 'invisible_log',
                  worldState: undefined,
                });
              }

              let dbConservation = construction;
              if (ActionType.BUILDINGS_CONVERT_TO_GOVERNMENT_AND_CONSUMER === card.action) {
                dbConservation = { ...dbConservation, label: 'add_build' };
              }

              await GameService.addActions(game?.id, {
                state: {
                  ...dbConservation,
                  label: dbConservation.label ? dbConservation.label : 'add_build',
                },
                type: 'invisible_log',
                worldState: {
                  ...game.state,
                  minorDanger: '',
                  dangerSubmited: true,
                  danger: '',
                  usedCards: [...game.state.usedCards, card?.action!],
                },
              });
            }
          }

          if (
            body?.length &&
            card.action === ActionType.BUILDINGS_CONVERT_TO_GOVERNMENT_AND_CONSUMER &&
            country
          ) {
            usedPowers = [
              ...usedPowers,
              `${body?.at(0)?.country}_government_residential_zoning_${rounds}`,
              `${body?.at(0)?.country}_government_residential_zoning_${rounds + 1}`,
              `${body?.at(0)?.country}_government_residential_zoning_${rounds + 2}`,
            ];
          }
          if (card.action === ActionType.DOWN_ALL_INFRASTRUCTURE && country) {
            const otherBuilds =
              game?.state?.constructions?.filter(
                (r) => r.typeBuild === ConstructionTypesBuild.RESOURCE && r.country === country,
              ) || [];

            const otherBuildsNr = otherBuilds?.length * MODIFIER_RESOURCES;
            modifier = modifier - otherBuildsNr;
          }

          if (card.action === ActionType.RESTORE_INFRASTRUCTURE_IN_THREE_CITIES && country) {
            usedPowers = [...usedPowers, `${country}_consumer_popular_demand`];
          }

          const newTurn = [...(game?.state?.turnFull || []), 'action'];
          const worldState = {
            ...game?.state,
            constructions: oldConstructions,
            turnFull: newTurn,
            usedPowers,
            usedCards: [...game.state.usedCards, card.action!],
            minorDanger: '',
            dangerSubmited: true,
            danger: '',
            modifier,
          };
          setGame({ ...game, state: worldState });
          await Promise.all([
            GameService.addActions(game?.id, {
              state: {
                card,
                card_value: card?.card_value,
                country,
                label: 'action',
                procentX: 0,
                procentY: 0,
                type: '',
                typeBuild: 'power',
              },
              type: 'visible_log',
              worldState: undefined,
            }),
            GameService.addActions(game?.id!, {
              state: undefined,
              type: 'world_state',
              worldState,
            }),
          ]);
          setCountry('');
          setBuild(undefined);
        }
      } catch (e: any) {
        const message = e?.response?.statusText || 'Error';
        toast(message, { type: 'error' });
      }
    },
    [country, game, user?.id],
  );

  return (
    <div className="w-full h-screen flex flex-col justify-between">
      <Map
        selectedBuild={selectedBuild}
        changeGame={(state) => changeGame({ ...state, userId: user?.id })}
        changeGameByPower={changeGameByPower}
        setPosition={setCountry}
        country={country}
        gameId={game?.id!}
        yourTurn={currentActor === user?.id}
        constructions={constructions?.filter((c) => !!c)}
        usedPowers={game?.state.usedPowers?.length ? game.state.usedPowers : []}
        rounds={game?.state.rounds || 1}
      />
      <PlayersGame
        selectedBuild={selectedBuild}
        players={game?.players}
        meRole={meRole!}
        usedPowers={game?.state.usedPowers?.length ? game.state.usedPowers : []}
        currentActor={currentActor!}
        nextActor={nextActor}
        changeInstitution={changeInstitution}
        changeSuperPower={changeSuperPower}
        selectAction={changeAction}
        selectBuild={(newBuild) => setBuild(newBuild)}
        institutuinCards={institutuinCards}
        currentCards={currentCards}
        constructionCount={constructionCount}
        modifier={modifier}
        emission={emission}
        resourcesCount={resourceCount}
        greenResourcesCount={greenResourceCount}
        rounds={game?.state.rounds || 1}
        usedCards={game?.state?.usedCards || []}
        constructions={
          game?.state?.constructions && game?.state?.constructions?.length
            ? game?.state?.constructions?.filter((c) => !!c)
            : []
        }
        gameId={game?.id!}
        disabledPower={!!(game?.state?.turnFull?.length && game?.state?.turnFull?.length > 0)}
      />
      <Resources
        count={
          (meCards?.greenResources || 0) + (meCards?.resources || 0) + (meCards?.institutions || 0)
        }
      />
      <BottomGameBlock
        chatId={game?.chatId!}
        constructionCount={constructionCount}
        greenConstructionCount={greenConstructionCount}
        resources={resourceCount}
        greenResources={greenResourceCount}
        institutions={institutuinCards.length}
        currentCards={currentCards}
        institutuinCards={institutuinCards}
        role={meRole?.role!}
        gameId={game?.id!}
      />
      {winnerModal ? <WinnerModal isOpen={winnerModal} onSubmit={endGame} /> : null}
      {overModal ? <OverModal isOpen={overModal} onSubmit={endGame} /> : null}
      {dangerModal && game?.id && danger?.length ? (
        <DangerCard
          key="danger"
          isOpen={dangerModal}
          isMajorDanger={false}
          onSubmit={() => {
            setDangerModal(false);
            setDanger('');
          }}
          constructions={constructions?.filter((c) => !!c)}
          gameId={game?.id!}
          state={game?.state!}
          danger={danger!}
          round={game?.state.rounds?.toString() || '1'}
        />
      ) : null}
      {dangerMajorModal && game?.id && dangerMajor?.length ? (
        <DangerCard
          key="majorDanger"
          isOpen={dangerMajorModal}
          isMajorDanger={true}
          onSubmit={() => {
            setDangerMajorModal(false);
            setDangerMajor('');
          }}
          constructions={constructions?.filter((c) => !!c)}
          gameId={game?.id!}
          state={game?.state!}
          danger={dangerMajor!}
          round={game?.state.rounds?.toString() || '1'}
        />
      ) : null}
    </div>
  );
};

export default Game;
