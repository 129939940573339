import React from 'react';

interface DialogProps {
  isOpen: boolean;
  onClose?: () => void;
  title: string;
  subtitle: string;
  region: string;
}

const Dialog: React.FC<DialogProps> = ({ isOpen, onClose, title, subtitle, region }) => {
  if (!isOpen) return null;

  const formattedSubtitle = subtitle.replace('<<region>>', region);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Overlay */}
      <div className="absolute inset-0 bg-black/50" onClick={onClose} />

      {/* Dialog Content */}
      <div className="relative rounded-[16px] overflow-hidden w-[343px] shadow-xl border-4 border-solid border-[#57C05B]">
        {/* Top Section with Checkmark */}
        <div className="bg-[#208B3A] pt-2 pb-4 flex justify-center items-center">
          <div className="w-[160px] h-[140px]">
            <svg
              className="w-full h-full text-[#E0FFE9]"
              viewBox="0 0 80 60"
              fill="none"
              strokeWidth="8"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="20 30 35 45 60 20" />
            </svg>
          </div>
        </div>

        {/* Bottom Section with Text */}
        <div className="bg-[#E0FFE9] py-4 px-4">
          <div className="space-y-4">
            {/* Title */}
            <p className="text-[#208B3A] text-base font-bold text-center font-nippo">{title}</p>

            {/* Subtitle */}
            <p className="text-[#000000CC] text-xs text-center font-nippo">
              {formattedSubtitle?.toUpperCase()}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dialog;
