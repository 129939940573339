import JoinLobby from '../assets/JoinLobby';
import NewLobby from '../assets/NewLobby';
import Rules from '../assets/Rules';
import Tutorial from '../assets/Tutorial';

export const PAGES = [
  { name: 'Create new Lobby', path: undefined, icon: NewLobby },
  { name: 'Join Lobby', path: '/join', icon: JoinLobby },
  { name: 'Tutorial', path: '/tutorial', icon: Tutorial },
  { name: 'Rules', path: '/rules', icon: Rules },
];
